import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  RefreshControl,
} from 'react-native';
import { useTheme, TextInput, Text, Title, Caption, Headline, Button, IconButton } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import { Loader } from '../Components/Loader'
import { getBank } from "../Services/api"
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

export const Bank = () => {
  const theme = useTheme();
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [refreshing, setRefreshing] = React.useState(false);

  React.useEffect(() => {
    fetchData();
  }, [])
  async function fetchData() {
    const response = await getBank();
    setData(response.data)
    setIsLoading(false);
  }
  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    fetchData();
    setRefreshing(false);
  }, []);

  if (isLoading) {
    return (<Loader />)
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.colors.background,
        }}
      >
        <ScrollView contentContainerStyle={styles.scrolls}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }>
          <View style={{ marginTop: 20, marginHorizontal: 10, elevation: 5, overflow: 'hidden', padding: 20, backgroundColor: theme.colors.secondaryCard, height: 210, borderRadius: 10 }}>
            <MaterialCommunityIcons name={'bank-outline'} size={160} color={theme.colors.secondaryIcon} style={{ position: 'absolute', top: 20, right: 20 }} />
            {!data?<Text style={{ marginBottom: 5, fontSize: 16 }}>Please add Bank Details</Text>:
            <>
            <Text style={{ marginBottom: 5, fontSize: 16 }}>{data.bank_name}</Text>
            <Text style={{ marginBottom: 5 }}>Recipient Name</Text>
            <Text style={{ marginBottom: 5, fontSize: 16 }}>{data.u_name}</Text>
            <Text style={{ marginBottom: 5 }}>Account No</Text>
            <Text style={{ marginBottom: 5, fontSize: 16 }}>{data.account_no}</Text>
            <Text style={{ marginBottom: 5 }}>IFSC</Text>
            <Text style={{ marginBottom: 5, fontSize: 16 }}>{data.ifsc_no}</Text>
            </>
          }
          </View>
          <View style={{ width: '100%', flexDirection: 'row', padding: 10, justifyContent: 'space-between' }}>
            {data ?
              <Button
                labelStyle={{ color: theme.colors.primary }}
                style={{ borderColor: theme.colors.primary, marginTop: 10 }}
                mode="outlined"
                onPress={() => navigation.navigate('BankDetails', { mode: 1,data:data,id:data.id })}
                contentStyle={{ width: 160 }}
              >
                Edit Bank
              </Button> :
              <Button
                style={{ backgroundColor: theme.colors.primary }}
                labelStyle={{ color: '#fff' }}
                mode="contained"
                onPress={() => navigation.navigate('BankDetails', { mode: 0 })}
                contentStyle={{ width: 160 }}
              >
                Add Bank
              </Button>
            }
          </View>
          <Text style={{ fontSize: 18, textAlign: 'center',marginTop:20 }}>OR</Text>
          <View style={{ margin: 20 }}>
          <View style={{ borderWidth: 1, borderColor: theme.colors.primaryContainer, borderRadius: 5, padding: 10, backgroundColor: theme.colors.background, elevation: 5, marginTop: 10, marginBottom: 20 }}>
            <Text style={{ fontSize: 18, marginBottom: 10, textAlign: 'center' }}>Please Send</Text>
            <Text style={{ fontSize: 22, marginBottom: 10, textAlign: 'center', color: theme.colors.primary }}>QR</Text>
            <Text style={{ fontSize: 18, marginBottom: 10, textAlign: 'center' }}>on Whatsapp for widthdraw.</Text>
          </View>
            <Button
              labelStyle={{ color: '#fff' }}
              contentStyle={{ backgroundColor: theme.colors.primary, height: 50 }}
              mode="contained"
              onPress={() => sendWhatsApp()}
              style={{ width: '100%' }}
            >
              Watsapp
            </Button>
          </View>
        </ScrollView>
      </View>
    )
  }
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
})