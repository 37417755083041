import React from 'react';
import {
  View,
  Linking,
  StyleSheet,
  Image,
  ScrollView,
} from 'react-native';
import { useTheme, Text, Button } from 'react-native-paper';
import { Images } from "../Constants";
import { getUser } from "../Services/api"
import { useUserStore } from '../Services/zustand'

export const QrCode = () => {
  const theme = useTheme();
  const { user } = useUserStore();

  const sendWhatsApp = () => {
    let refer_code = user.mobile_no
    let link = "https://api.whatsapp.com/send?phone=919233070576&text=Deposited%20Please%20Check%21%0AMy%20id%20is%20" + refer_code
    Linking.openURL(link)
  };
  React.useEffect(() => {
    fetchData();
  }, [])
  async function fetchData() {
    const response = await getUser();
    setData(response.user)
  }
  return (
    <View style={[styles.container, { backgroundColor: theme.colors.background }]}>
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: 18, marginBottom: 10 }}>Scan and Pay</Text>
          <View style={{ width: 300, height: 500 }}>
            <Image
              source={Images.qr}
              style={{ width: '100%', height: '100%' }}
            />
          </View>
          <View style={{ borderWidth: 1, borderRadius: 5, padding: 10, backgroundColor: theme.colors.background, elevation: 5, marginTop: 10,marginBottom: 10 }}>
            <Text style={{ fontSize: 22 }}>Note:</Text>
            <Text style={{ fontSize: 18 }}>After payment send us screenshot in Watsapp will update in minutes.</Text>
            <Text style={{ fontSize: 18 }}>Thank You</Text>
          </View>
          <Button
            labelStyle={{ color: '#fff' }}
            contentStyle={{ backgroundColor: theme.colors.primary,height:50 }}
            mode="contained"
            onPress={() => sendWhatsApp()}
            style={{ width: '100%' }}
          >
            Watsapp
          </Button>
        </View>
      </ScrollView>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
});