const wLogo = require('../../assets/images/white-logo.png');
const Logo = require('../../assets/images/logo.png');
const reffer = require('../../assets/images/reffer.png');
const qr = require('../../assets/images/qr-code.png');

export default {
  Logo,
  wLogo,
  reffer,
  qr
};
