import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  Linking,
} from 'react-native';
import { useTheme, Text, Title, Button } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useNavigation } from '@react-navigation/native';
import { useUserStore } from '../Services/zustand'

export const Process = ({route}) => {
  const theme = useTheme();
  const navigation = useNavigation();
  const {message}= route.params
  const { user } = useUserStore();

  const sendWhatsApp = () => {
    let refer_code = user.mobile_no
    let link = "https://api.whatsapp.com/send?phone=919233070576&text=Widthrawal%20Please%20Check%21%0AMy%20id%20is%20" + refer_code
    Linking.openURL(link)
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.surface,
      }}
    >
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ marginTop: 80,marginBottom:80,alignItems:'center' }}>
          <MaterialCommunityIcons name="check-circle" size={120} color={theme.colors.primary} />
          <Title style={{ marginBottom: 10, marginTop: 10 }}>Congrats!</Title>
          <Text style={{ marginBottom: 10, marginTop: 10 }}>{message}</Text>
        </View>
        <View style={{ margin: 20 }}>
          <View style={{ borderWidth: 1, borderColor: theme.colors.primaryContainer, borderRadius: 5, padding: 10, backgroundColor: theme.colors.background, elevation: 5, marginTop: 10, marginBottom: 20 }}>
            <Text style={{ fontSize: 18, marginBottom: 10, textAlign: 'center' }}>Please Send</Text>
            <Text style={{ fontSize: 22, marginBottom: 10, textAlign: 'center', color: theme.colors.primary }}>QR</Text>
            <Text style={{ fontSize: 18, marginBottom: 10, textAlign: 'center' }}>on Whatsapp for widthdraw.</Text>
          </View>
          <Button
            labelStyle={{ color: '#fff' }}
            contentStyle={{ backgroundColor: theme.colors.primary, height: 50 }}
            mode="contained"
            onPress={() => sendWhatsApp()}
            style={{ width: '100%',marginBottom:40 }}
          >
            Send Now
          </Button>
          <Button
            labelStyle={{ lineHeight: 26, color: 'white', }}
            style={{ width: '100%' }}
            contentStyle={{ height: 50, backgroundColor: theme.colors.secondary, borderRadius: 25 }}
            onPress={() => navigation.navigate('Home')}>
            Back to Home
          </Button>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
})
