import React from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  Alert,
  Linking,
  NativeModules,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AwesomeAlert from 'react-native-awesome-alerts';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTheme, Text, Title, Snackbar, Button, Divider, TextInput } from 'react-native-paper';
import { useWalletStore } from '../Services/zustand'
import { addWallet, subWallet, getBank, walletCheck } from "../Services/api"

export const Wallet = ({ route }) => {
  const UPI = NativeModules.UPI;
  const { opt } = route.params
  const theme = useTheme();
  const navigation = useNavigation();
  const { wallet, winWallet, discountWallet, giftWallet, setWallet } = useWalletStore();
  const [msg, setMsg] = React.useState('');
  const [addtext, setAddtext] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState('topup');
  const [loading, setLoading] = React.useState(false);
  const onDismissSnackBar = () => setVisible(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [mTitle, setMTitle] = React.useState('');
  const [mMessage, setMMessage] = React.useState('');

  React.useEffect(() => {
    if (opt == 1) {
      setValue('topup');
    } else {
      setValue('topless');
    }
  }, [])
  const openLink = async (data) => {
    let txn = 'hp' + Date.now().toString() + 'upi';
    let UpiUrl =
      "upi://pay?pa=paytmqr1w991r21f0@paytm&pn=SGTEER&tr=" + txn + "&am=" +
      addtext +
      "&mam=null&cu=INR&url=https://MyUPIApp&refUrl=https://MyUPIApp";
    let response = await UPI.openLink(UpiUrl);

    let result = response.split('&').map(s => s.split('='));
    if (result[2][1] == 'SUCCESS') {
      setIsLoading(true)
      const response = await addWallet(addtext, result[0][1]);
      setWallet({ wallet: response.data, discount: discountWallet, winWallet: winWallet, giftWallet: giftWallet })
      setIsLoading(false)
      navigation.navigate('Process', { message: "You had successfully added Money to your Wallet" })
    }
  };
  const processWallet = async () => {
    setIsLoading(true)
    const response = await subWallet(addtext);

    if (response.status == 'error') {
      setMTitle('Alert')
      setMMessage('Please check Wallet Balance')
      setShowAlert(true)
      setIsLoading(false)
    } else {
      if (parseInt(response.data) == 1) {
        setMTitle('Alert')
        setMMessage('Please wait!\nyour previous withdrawal request is under process')
        setShowAlert(true)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setWallet({ wallet: response.data, discount: discountWallet, winWallet: winWallet, giftWallet: giftWallet })
        navigation.navigate('Process', { message: "You have successfully submitted Withdraw Request" })
      }
    }
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
      }}
    >
      <ScrollView contentContainerStyle={styles.scrolls}>
        <View style={{ marginHorizontal: 10 }}>
          <Title style={{ marginBottom: 10, marginTop: 10, color: theme.colors.secondary, fontSize: 14 }}>Wallet Balance</Title>
          <View style={{ flex: 1, flexDirection: 'row', marginBottom: 20 }}>
            <MaterialCommunityIcons name={'currency-inr'} size={15} color={theme.colors.text} style={{ marginTop: 17 }} />
            <Title style={{ marginBottom: 10, marginTop: 10, color: theme.colors.text, fontSize: 32 }}>{wallet}.00</Title>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20, height: 160 }}>
            <View style={[styles.boxStyle, { backgroundColor: theme.colors.primary }]}>
              <MaterialCommunityIcons name={'arrow-down'} size={20} color={theme.colors.card} style={{ marginTop: 17 }} />
              <Text style={{ color: theme.colors.card }}>RECEIVED</Text>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <MaterialCommunityIcons name={'currency-inr'} size={15} color={theme.colors.card} style={{ marginTop: 20 }} />
                <Title style={{ marginBottom: 10, marginTop: 10, color: theme.colors.card, fontSize: 24 }}>{wallet}</Title>
              </View>
            </View>
            <View style={[styles.boxStyle, { backgroundColor: theme.colors.secondary }]}>
              <MaterialCommunityIcons name={'arrow-up'} size={20} color={theme.colors.card} style={{ marginTop: 17 }} />
              <Text style={{ color: theme.colors.card }}>SPENT</Text>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <MaterialCommunityIcons name={'currency-inr'} size={15} color={theme.colors.card} style={{ marginTop: 20 }} />
                <Title style={{ marginBottom: 10, marginTop: 10, color: theme.colors.card, fontSize: 24 }}>{wallet}</Title>
              </View>
            </View>
          </View>

          <Divider />
          <View style={{ flex: 1, flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
            <Button
              mode={opt == 1 ? "contained" : "outlined"}
              onPress={() => { setValue('topup') }}
              labelStyle={{ lineHeight: 26, color: opt == 1 ? '#fff' : '#000', }}
              style={{ borderColor: theme.colors.primary }}
              disabled={true}
            >
              Deposite
            </Button>
            <Button
              mode={opt == 2 ? "contained" : "outlined"}
              onPress={() => { setValue('topless') }}
              labelStyle={{ lineHeight: 26, color: opt == 2 ? '#fff' : '#000', }}
              style={{ borderColor: theme.colors.primary }}
              loading={loading}
            >
              Withdraw
            </Button>
          </View>
          <Divider />
          <View style={{ marginTop: 20 }}>
            {value == 'topup' ? null :
              <View style={{ backgroundColor: theme.colors.info, padding: 20, borderRadius: 10 }}>
                <Text style={{ fontSize: 14, color: '#fff' }}>Please Note:</Text>
                <Text style={{ fontSize: 14, marginBottom: 10, color: '#fff' }}>pay out time 8am-10am & 8pm-10pm</Text>
              </View>
            }
            <Text style={{ fontSize: 20, marginTop: 10, marginBottom: 10 }}>{value == 'topup' ? "Topup" : "Withdraw from"} Wallet</Text>
            <TextInput
              label="Amount"
              value={addtext}
              mode={'outlined'}
              onChangeText={text => setAddtext(text)}
              left={<TextInput.Icon name={() => <MaterialCommunityIcons name={'currency-inr'} size={20} color={theme.colors.text} />} />}
              theme={{ colors: { primary: theme.colors.primary, underlineColor: 'transparent', } }}
              style={{ marginBottom: 5, width: '100%' }}
            />
          </View>
        </View>
        <View style={{ flexDirection: 'column', marginTop: 100, flex: 1, width: '100%' }}>
          <Button
            labelStyle={{ lineHeight: 26, color: 'white', }}
            style={{ width: '100%', marginBottom: 5 }}
            contentStyle={{ height: 55, backgroundColor: theme.colors.primary, borderRadius: 0 }}
            loading={isLoading}
            onPress={() => value == 'topup' ? null : processWallet()}>
            Continue
          </Button>
        </View>
        <AwesomeAlert
          show={showAlert}
          showProgress={false}
          title={mTitle}
          message={mMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showCancelButton={false}
          showConfirmButton={true}
          confirmText="OK"
          cancelButtonColor={theme.colors.primary}
          onCancelPressed={() => {
            setShowAlert(false);
          }}
          onConfirmPressed={() => {
            setShowAlert(false);
          }}
        />
      </ScrollView>
      <Snackbar
        visible={visible}
        onDismiss={onDismissSnackBar}
        duration={1000}
        style={{ bottom: 0, textAlign: 'center', position: 'absolute' }}
      >
        {msg}
      </Snackbar>
    </View>
  );
}
const styles = StyleSheet.create({
  scrolls: {
    width: '100%',
    flexGrow: 1,
  },
  boxStyle: {
    height: 150, width: '48%', borderRadius: 15, padding: 10
  },
})
